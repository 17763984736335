<template>
  <ModalMain transition-name="modal-slide-up" @close="onClose">
    <div class="px-4 md:px-7 w-full pt-4 pb-3">
      <header class="animate-fade-in text-center mb-6 px-2">
        <h1 class="text-3xl tracking-widest mt-6 mb-5 text-center">{{ currencyCode }} Deposit Wallet</h1>
        <p class="text-slate-100 text-md lg:text-md">
          Use your deposit address to fund your MetaWin account balance without connecting to the site.
        </p>
      </header>

      <transition name="fade-fast">
        <Spinner v-if="!ready" class="w-full !py-0"/>
      </transition>

      <transition name="fade-fast">
        <div v-if="ready">
          <div v-if="address && dataUrl" class="flex items-center justify-center mb-7">
            <vue-qrcode :value="dataUrl" class="rounded-lg"/>
          </div>

          <div class="relative" @click.prevent.stop="copyAddress()">
            <input
              class="block relative w-full bg-slate-600 border-slate-600 rounded-lg py-3 px-4 border-2 outline-none focus:border-slate-200 focus:ring-0 autofill:bg-slate-600 transition-all appearance-none overflow-hidden pr-10 whitespace-nowrap truncate cursor-pointer hover:appearance-none hover:text-white disabled:text-white"
              name="referralLink"
              type="text"
              placeholder="Unavalable"
              disabled
              :value="copyAddressText ?? address"
            >
            <span class="absolute block right-2 top-[2px] bg-slate-600 z-10 text-slate-100 select-none py-2 cursor-pointer">
              <span title="Copy Address" class="text-xl icon-ico-copy flex justify-center items-center w-8 h-8 text-white"/>
            </span>
          </div>

          <h2 v-show="errorMessage" class="w-full inline-block text-red text-md mt-4 text-center">
            <span v-html="errorMessage"/>
          </h2>
        </div>
      </transition>
</div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapActions, mapState } from 'pinia';
import Web3 from 'web3';
import VueQrcode from 'vue-qrcode';
import { useUiStore } from '@/store/ui';
import { useDepositAccountStore } from '@/store/depositAccount';
import { getConfig } from '@/utils/getConfig';

export default defineComponent({
  name: 'ModalDepositAccountMain',
  components: {
    VueQrcode,
  },
  data() {
    return {
      ready: false,
      loading: false,
      currencyCode: 'ETH',
      errorMessage: null,
      copyAddressText: null,
      address: null,
      dataUrl: null,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showDepositAccountModal',]),
    ...mapState(useDepositAccountStore, ['accounts',]),
  },
  async mounted() {
    await this.getOrAdd();
  },
  beforeUnmount() {
    this.showDepositAccountModal = false;
  },
  methods: {
    ...mapActions(useDepositAccountStore, ['init', 'add',]),
    async getOrAdd() {
      await this.init();

      let account = this.accounts?.find(a => a.currencyCode === this.currencyCode);

      if (!account) { account = await this.addAccount(this.currencyCode); }

      if (!account) {
        this.ready = true;
        return;
      }

      this.address = account?.address;
      this.setDataUrl();
      this.ready = true;
    },
    async addAccount(currencyCode) {
      this.loading = true;
      try {
        return await this.add(currencyCode);
      } catch (err) {
        switch (err.message) {
          case 'DepositAccountAlreadyExists':
            this.errorMessage = 'A deposit wallet for the selected currency already exists.';
            break;
          case 'DepositAccountCreationFailed':
            this.errorMessage = 'We have been unable to create your deposit wallet.';
            break;
          default:
            this.errorMessage = 'An unexpected error occurred.';
            break;
        }
      } finally {
        this.loading = false;
      }
    },
    setDataUrl() {
      switch (this.currencyCode) {
        default: {
          const chainId = Web3.utils.hexToNumber(getConfig('ETHEREUM_NETWORK_ID'));
          this.dataUrl = `ethereum:${this.address}?chainId=${chainId}`;
          break;
        }
      }
    },
    copyAddress() {
      this.$copyText(this.address, undefined, (err) => {
        if (err) {
          this.copyAddressText = 'Copy Failed';
          useNuxtApp().$rollbar.error('Failed to copy:', err);
        } else {
          this.copyAddressText = 'Copied!';
          setTimeout(() => {
            this.copyAddressText = null;
          }, 2000);
        }
      });
    },
    onClose() {
      this.showDepositAccountModal = false;
    },
  },
});
</script>
